<template>
  <div>
    <Loading v-show="$store.state.loadingStatus"></Loading>
  </div>
</template>

<script>
import Loading from "@/components/loader/Loading.vue";

export default {
  name: "CommonComponent",
  components: {
    Loading
  }
}
</script>