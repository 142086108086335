import restClient from '@/service/common/interceptors';
import { initResponseData } from '@/service/common/common';

export default {
	loginProc: (parameter) => {
		return new Promise((resolve, reject) => {
			restClient
				.post('/auth/login.api', parameter)
				.then((response) => {
					response = initResponseData(response);
					//로그인 처리!!!
					let result = response.data.header.result;
					let message = response.data.header.message;
					let token = response.data.body.data.authToken;
					let uuid = response.data.body.data.uuid;
					let refreshToken = response.data.body.data.refreshToken;
					let user = response.data.body.data.data?.loginId;
					let name = response.data.body.data.data?.name;
					let data = response.data.body.data.data;
					let individualList =
						response.data.body.data.data?.individualList;
					let serviceList = response.data.body.data.data?.serviceList;

					resolve({
						result,
						message,
						token,
						uuid,
						refreshToken,
						user,
						name,
						data,
						individualList,
						serviceList
					});
				})
				.catch((e) => {
					reject(e);
				});
		});
	},
	refreshProc: (parameter) => {
		return new Promise((resolve, reject) => {
			restClient
				.post('/auth/refresh.api', parameter)
				.then((response) => {
					response = initResponseData(response);
					//토큰 발급 처리!!!
					let result = response.data.header.result;
					let token = response.data.body.data.authToken;
					let uuid = response.data.body.data.uuid;
					let refreshToken = response.data.body.data.refreshToken;
					let data = response.data.body.data.data;
					resolve({ token, uuid, refreshToken, result, data });
				})
				.catch((e) => {
					reject(e);
				});
		});
	},
	autoLoginProc(payload) {
		return new Promise((resolve, reject) => {
			restClient
					.post('/auth/auto-login.api', payload)
					.then((response) => {
						response = initResponseData(response);
						//로그인 처리!!!
						let result = response.data.header.result;
						let message = response.data.header.message;
						let token = response.data.body.data.authToken;
						let uuid = response.data.body.data.uuid;
						let refreshToken = response.data.body.data.refreshToken;
						let user = response.data.body.data.data?.loginId;
						let name = response.data.body.data.data?.name;
						let data = response.data.body.data.data;
						let individualList =
								response.data.body.data.data?.individualList;
						let serviceList = response.data.body.data.data?.serviceList;

						resolve({
							result,
							message,
							token,
							uuid,
							refreshToken,
							user,
							name,
							data,
							individualList,
							serviceList
						});
					})
					.catch((e) => {
						reject(e);
					});
		});
	}
};
