<template>
  <nav class="navarea">
    <ul>
      <li @click="goRouter({name: 'ExternalPage', params:{path: 'examine', menuName: '검진 예약'}})" :class="{active: isReservation}" class="nav_menu1">
        <div class="icon">검진</div>
        <div class="tit">검진</div>
      </li>
      <li @click="goRouter({name: 'AnalysisRecord'})" :class="{active: isMenuHealth}" class="nav_menu2">
        <div class="icon">건강</div>
        <div class="tit">건강</div>
      </li>
      <li @click="goRouter({name: 'Index'})" :class="{ active: isMenuHome}" class="nav_menu3">
        <div class="icon">홈</div>
      </li>
      <li @click="goRouter({name: 'CustomizedCare', params: {division: 'ex'}})" :class="{ active: isMenuContents}" class="nav_menu4 ">
        <div class="icon">콘텐츠</div>
        <div class="tit">콘텐츠</div>
      </li>
      <li @click="goRouter({name: 'ExternalPage', params:{path: 'health-mall', menuName: '웰니스몰'}})" class="nav_menu5">
        <div class="icon">웰니스몰</div>
        <div class="tit">웰니스몰</div>
      </li>
    </ul>
  </nav>
</template>

<script>
import {mapMutations} from "vuex";

export default {
    methods: {
        goRouter(payload) {
            this.$router.push(payload);
        }
    },
  computed: {
    isReservation() {
      switch (this.$route.name || '') {
        case 'ExternalPage':
          return true;
      }
      return false;
    },
    isMenuHealth() {
      switch (this.$route.name || '') {
        case 'AnalysisRecord':
        case 'BodyTypeRegistration':
        case 'BodyAnalysis':
        case 'ExtraOfficialSns':
          return true;
      }
      return false;
    },
    isMenuHome() {
      switch (this.$route.name || '') {
        case 'Index':
          return true;
      }
      return false;
    },
    isMenuContents() {
      switch (this.$route.name || '') {
        case 'CustomizedCare':
          return true;
      }
      return false;
    },
  }
}
</script>