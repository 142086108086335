<template>
    <!--  <button class="btn bg_green" data-target="#popup2" data-toggle="modal">팝업1</button>-->
    <div id="alertArea" class="modal" data-keyboard="false" data-backdrop="static">
        <div class="modal_popup">
            <div class="popup_inner" v-html="getAlertMsg">
            </div>
            <div class="popup_btn one">
                <button type="button" id="alertTrue" class="modal_close color_blue" data-dismiss="modal" aria-hidden="true"
                        title="팝업 닫기">확인
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name    : "AlertComponent",
    computed: {
        ...mapGetters(['getAlertMsg'])
    }
}
</script>

<style scoped></style>