export default [
	{
		name: 'Index',
		path: '/',
		component: () => import('@/views/main/Index.vue'),
		meta: { auth: true, menuName: 'DB손해보험 헬스케어', typeName: 'main', footer: true },
	},
	{
		name: 'EventPage',
		path: '/event',
		component: () => import('@/views/main/EventPage.vue'),
		meta: { menuName: '이벤트', typeName: 'home' }
	},
	{
		name: 'ExternalEvent',
		path: '/event/:encPhoneNumber',
		component: () => import('@/views/main/ExternalEvent.vue'),
		meta: { menuName: '이벤트', typeName: 'home' }
	}
];
