export default [
	//진료 예약
	{
		name: 'MedicalAppointment',
		path: '/diagnosis/medical-appointment',
		component: () => import('@/views/diagnosis/MedicalAppointment.vue'),
		meta: { menuName: '진료 예약', typeName: 'home' },
	},

	//진료 내역
	{
		name: 'MedicalInquiry',
		path: '/diagnosis/medical-inquiry',
		component: () => import('@/views/diagnosis/MedicalInquiry.vue'),
		meta: { menuName: '신청 내역', typeName: 'home' },
	},
];
