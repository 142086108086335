<template>
    <div class="subtab2">
        <ul>
            <li v-for="item in individualList" :key="item" :class="{ active: serviceDiv === item }">
                <router-link :to="{name: 'Specialization', params: {serviceDiv: item}}">{{ individualName(item) }}</router-link>
            </li>
        </ul>
    </div>
</template>

<script>

import {sessionManager} from "@/utils/session-manager";

export default {
    name: 'ProfessionalServiceTab',
    data() {
        return {
            individual: '',
        }
    },
    computed: {
        serviceDiv() {
            return this.$route.params.serviceDiv;
        },
        individualList() {
            return sessionManager.getMember().individualList;
        },
    },
    methods: {
        individualName(item){
            if (item === 'care') {
                return this.individual = '치료지원';
            } else if (item === 'cash') {
                return this.individual = '현물급부';
            } else if (item === 'mental') {
                return this.individual = '멘탈케어';
            }
        }
    },
};
</script>

<style></style>