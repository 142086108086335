<template>
    <div class="subtab2">
        <ul>
            <li :class="{ active: division === 'ex' }">
                <router-link :to="{name: 'CustomizedCare', params: {division: 'ex'}}">맞춤운동</router-link>
            </li>
            <li :class="{ active: division === 're' }">
                <router-link :to="{name: 'CustomizedCare', params: {division: 're'}}">맞춤레시피</router-link>
            </li>
            <li :class="{ active: division === 'wa' }">
                <router-link :to="{name: 'CustomizedCare', params: {division: 'wa'}}">걷기SOS</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'CustomizedCareTab',
    computed: {
        division() {
            return this.$route.params.division;
        },
    }
};
</script>

<style></style>