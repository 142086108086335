import Vue from 'vue';
import store from '@/store';

Vue.prototype.$alert = async function (msg) {
	await store.dispatch('fetchAlertMsg', msg)
	return new Promise((resolve, reject) => {
		$("#alertTrue").click(() => resolve(true));
	});
};

Vue.prototype.$confirm = async function (msg) {
	await store.dispatch('fetchConfirmMsg', msg);
	return new Promise((complete, failed) => {
		$('#confirmTrue').click(function () {
			complete(true);
		});
		$('#confirmFalse').click(function () {
			complete(false);
		});
	});
};

Vue.prototype.$sleep = async function sleep(time) {
	return new Promise((resolve) => {
		setTimeout(resolve, time || 1000);
	});
};

Vue.prototype.$EventBus = new Vue();

