import Vue from 'vue';
import App from '@/App.vue';
import router from '@/routes/router';
import store from '@/store/index';
import $ from 'jquery';
import VueCookies from 'vue-cookies';
import BootstrapVue from 'bootstrap-vue';
import Bootstrap from 'bootstrap';
import Swiper from 'swiper';
Vue.config.productionTip = false;
import '@/assets/css/default.css';
import '@/assets/css/font.css';
import '@/assets/css/swiper.min.css';
import '@/assets/css/main.css';
import '@/assets/css/main-dev.css';
import '@/assets/css/contents.css';
import '@/assets/css/contents-dev.css';
import '@/assets/css/survey.css';

import '@/assets/js/swiper.min.js';
import '@/assets/js/jquery.dropselect';
import '@/assets/js/common_ui.js';
Vue.use(VueCookies);
Vue.use(BootstrapVue);
Vue.use(Bootstrap);

Vue.$cookies.config('7d');
import '@/utils/custom-prototype';
import '@/utils/filter';
import './registerServiceWorker'

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');
