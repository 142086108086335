<template>
  <div v-if="!isLoginPage && isIndexPage" :class="{ 'toparea2': mainFlag }" class="toparea">
    <div class="top_bar">
      <div class="d_area">
        <h1 class="title">{{ menuName }}</h1>
      </div>
    </div>
    <div class="top_btn">
      <router-link :to="{ name: 'CustomerServiceCenter' }" type="button" class="btn_call" title="전화걸기"></router-link>
    </div>
    <div class="topmenu">
      <button @click="openSideMenu" type="button" class="btn_allgnb" title="전체 메뉴 열림">
        <span class="line1"></span>
        <span class="line2"></span>
        <span class="line3"></span>
      </button>
    </div>
    <menu-bar></menu-bar>
  </div>

  <div v-else :class="{ 'toparea3': area3Flag }" class="toparea">
    <div class="top_bar">
      <div v-if="isExercise" class="d_area">
        <button @click="$router.go(-1)" class="btn_close" title="화면 닫기"></button>
        <!--
          <router-link :to="{ name: 'BackgroundMusic' }" class="btn_music" title="배경음악 화면으로 이동"></router-link>
        -->
      </div>
      <div v-else class="d_area">
        <button v-if="!isAddTerms" @click="$router.go(-1)" type="button" class="btn_back" title="이전 화면으로 이동"></button>
        <h1 class="title">{{ menuName }}</h1>
        <router-link v-if="!isHomeButton && !isTermsPage" :to="{ name: 'Index' }" class="btn_home"></router-link>
      </div>
    </div>
  </div>
</template>
<script>

import MenuBar from "@/components/layout/MenuBar.vue";

export default {
  components: {
    MenuBar
  },
  data() {
    return {
      mainFlag: true,
      mainList: [
        'Index',
        'ServiceGuide',
        'HealthActivities',
        'CustomizedCare',
        'CareDetail',
        'AnalysisRecord',
        'BodyTypeRegistration',
        'BodyAnalysis',
      ],
      area3Flag: false,
      area3List: [
        'ContentsList',
      ],
    };
  },
  methods: {
    getHeaderType(routeName) {
      if (this.mainList.includes(routeName)) {
        this.mainFlag = true;
        this.area3Flag = false;
      } else if (this.area3List.includes(routeName)) {
        this.area3Flag = true;
        this.mainFlag = false;
      } else {
        this.mainFlag = false;
        this.area3Flag = false;
      }
    },
    openSideMenu() {
      $(".allmenu").slideDown(200);
      $(".allmenu").addClass("active");
      $(".total_search_area").slideUp(200);
      $(".top_bar").addClass("active");
      $(".allmenu .btn_close").focus();
    }
  },
  computed: {
    isHomeButton() {
      return 'hideHomeButton' === this.$route.meta.typeName;
    },
    isExercise() {
      return this.$route.name === 'StartExercise';
    },
    isLoginPage() {
      return this.$route.name === 'Login';
    },
    isIndexPage() {
      return this.$route.name === 'Index';
    },
    isTermsPage() {
      return this.$route.name === 'DetailTerms';
    },
    isAddTerms() {
      return this.$route.name === 'AddTerm';
    },
    menuName() {
      if (typeof this.$route.meta === 'function') {
        return this.$route.meta(this.$route).menuName;
      }
      return this.$route.meta.menuName;
    },
  },
  watch: {
    $route(to, from) {
      this.getHeaderType(to.name);
    },
  }
};
</script>

<style></style>