export default [
	//특화서비스
	{
		name: 'Specialization',
		path: '/specialization/:serviceDiv',
		component: () => import('@/views/specialization/Specialization.vue'),
		meta: { menuName: '특화 서비스', typeName: 'home' },
	},

	//특화서비스 상세
	{
		name: 'SpecializationDetail',
		path: '/specialization/detail/:serviceDiv/:serviceKey',
		component: () =>
			import('@/views/specialization/SpecializationDetail.vue'),
		meta: (route) => ({
			menuName: route.params.menuName,
			typeName: 'home',
		}),
	},

	//특화서비스 상세
	{
		name: 'ServiceInfo',
		path: '/specialization/cash/service-info/:serviceDiv/:serviceKey',
		component: () => import('@/views/specialization/cash/ServiceInfo.vue'),
		meta: { menuName: '서비스 신청', typeName: 'home' },
	},

	//특화서비스 상세
	{
		name: 'ServiceHistory',
		path: '/specialization/cash/service-history/:serviceDiv/:serviceKey',
		component: () =>
			import('@/views/specialization/cash/ServiceHistory.vue'),
		meta: { menuName: '사용 내역 조회', typeName: 'home' },
	},

	//특화서비스 상세
	{
		name: 'BeneficiaryForm',
		path: '/specialization/cash/beneficiary/:serviceDiv/:serviceKey',
		component: () =>
			import('@/views/specialization/cash/BeneficiaryForm.vue'),
		meta: { menuName: '서비스 신청', typeName: 'home' },
	},

	//특화서비스 상세
	{
		name: 'RefundForm',
		path: '/specialization/cash/refund/:serviceDiv/:serviceKey',
		component: () => import('@/views/specialization/cash/RefundForm.vue'),
		meta: { menuName: '환급 신청', typeName: 'home' },
	},

	//특화서비스 상세
	{
		name: 'SurveyList',
		path: '/specialization/mental-care/survey-list',
		component: () =>
			import('@/views/specialization/mentalCare/SurveyList.vue'),
		meta: { menuName: '멘탈 케어', typeName: 'home' },
	},

	//특화서비스 상세
	{
		name: 'MentalQuestionnaire',
		path: '/specialization/mental-care/mental-questionnaire/:surveyNum',
		component: () =>
			import('@/views/specialization/mentalCare/MentalQuestionnaire.vue'),
		meta: { menuName: '멘탈 케어 설문', typeName: 'home' },
	},
];
