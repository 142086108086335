// 공통 메뉴의 router 정보

export default [
	/**
	 * 회원가입 이용약관
	 */
	{
		name: 'JoinTerm',
		path: '/join/term',
		component: () => import('@/views/membership/join/Term.vue'),
		meta: { menuName: '회원가입', typeName: 'hideHomeButton' },
	},

	/**
	 * 회원가입
	 */
	{
		name: 'JoinRegister',
		path: '/join/register',
		component: () => import('@/views/membership/join/Register.vue'),
		meta: { menuName: '회원가입', typeName: 'hideHomeButton' },
	},
	/**
	 * 회원가입 완료
	 */
	{
		name: 'JoinComplete',
		path: '/join/complete',
		component: () => import('@/views/membership/join/Complete.vue'),
		meta: { menuName: '회원가입 완료', typeName: 'hideHomeButton' },
	},
];
