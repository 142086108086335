<template>
    <div id="wrap" class="wrap">
        <header-page v-if="hasHeader"></header-page>

        <div v-if="hasContentsArea" class="midarea">
            <!-- S: contentsarea -->
            <analysis-tab v-if="isBodyType"></analysis-tab>
            <customized-care-tab v-else-if="$route.name === 'CustomizedCare'"></customized-care-tab>
            <professional-service-tab v-else-if="$route.name === 'Specialization'"></professional-service-tab>

            <div :class="paddingVal ? 'padding0' : ''" class=" contentsarea d_area" id="contentsarea">
                <router-view/>
            </div>
            <!-- //E: contentsarea -->
        </div>
        <router-view v-else/>
        <!-- <loading :loading="$store.state.loadingStatus"></loading> -->
        <footer-page v-if="$route.meta.footer"/>
        <alert-component></alert-component>
        <confirm-component></confirm-component>
        <common-component/>
    </div>
</template>

<script>
import FooterPage from '@/components/layout/FooterPage.vue';
import AlertComponent from '@/views/common/message/AlertComponent.vue';
import ConfirmComponent from '@/views/common/message/ConfirmComponent.vue';
import HeaderPage from '@/components/layout/HeaderPage.vue';
import AnalysisTab from '@/views/healthAnalysis/menu/AnalysisTab.vue';
import ProfessionalServiceTab from '@/views/specialization/menu/ProfessionalServiceTab.vue';
import CustomizedCareTab from '@/views/contents/menu/CustomizedCareTab.vue';
import CommonComponent from "@/components/layout/CommonComponent.vue";

export default {
    name      : "App",
    components: {
        CommonComponent,
        FooterPage,
        ConfirmComponent,
        AlertComponent,
        HeaderPage,
        AnalysisTab,
        CustomizedCareTab,
        ProfessionalServiceTab
    },
    data() {
        return {
            contentsType: ''
        }
    },
    computed: {
        paddingVal() {
            switch (this.$route.name) {
                case 'CareDetail':
                case 'EventPage':
                case 'RefundForm':
                case 'BeneficiaryForm':
                    return true;
            }
            return false;
        },
        hasHeader() {
            switch (this.$route.name) {
                case 'Login':
                case 'Logout':
                case 'CareDetail':
                case 'ExternalEvent':
                    return false;
            }
            return true;
        },
        hasContentsArea() {
            switch (this.$route.name) {
                case 'Index':
                case 'Login':
                case 'Logout':
                case 'ExternalEvent':
                    return false;
            }
            return true;
        },
        isBodyType() {
            switch (this.$route.name) {
                case 'AnalysisRecord':
                case 'BodyTypeRegistration':
                case 'BodyAnalysis':
                    return true;
            }
            return false;
        },
    }
};
</script>