import axios from 'axios';
import store from '@/store';
import router from '@/routes/router';
import {sessionManager} from '@/utils/session-manager';
import Vue from 'vue';

const instance = axios.create({
    baseURL: '/api',
    timeout: 1000 * 60 * 5, // 60초
});

instance.interceptors.request.use(
        (config) => {
            store.commit('startSpinner');
            const token = sessionManager.getMember().token;
            if (token) {
                config.headers = {'chc-authorization': 'Bearer ' + token};
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
);

instance.interceptors.response.use(
        async (response) => {
            store.commit('endSpinner');
            if (
                    response.data.header.cmdCode === 'M001M002' ||
                    response.data.header.cmdCode === 'M001M005' ||
                    response.data.header.cmdCode === 'M001T003'
            ) {
                //토큰 만료시... 추후 refresh Token 사용해서 수정요망 => M001T003
                await Vue.prototype.$alert(response.data.header.message);
                await router.push({name: 'Logout'});
                return {};
            }

            return response;
        },
        (error) => {
            store.commit('endSpinner');
            router.push('/common/error');
            return Promise.reject(error);
        }
);

// 생성한 인스턴스를 익스포트 합니다.
export default instance;
