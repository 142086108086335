export default [
    {
        name: 'HealthInfoList',
        path: '/health-info/:category',
        component: () => import('@/views/healthInfo/HealthInfoList.vue'),
        meta: { auth: true, menuName: '건강정보', typeName: 'home' },
    },
    {
        name: 'HealthInfoDetail',
        path: '/health-info/detail/:category',
        component: () => import('@/views/healthInfo/HealthInfoDetail.vue'),
        meta: { auth: true, menuName: '건강정보', typeName: 'home' },
    },
];
