import restClient from "@/service/common/interceptors";

const initResponseData = (response) => {
	response.status = response.status || 0;
	if (response.status !== 200) {
		throw new Error('데이터 처리 중 문제가 발생하였습니다. ');
	}

	response = response || {};
	response.data = response.data || {};
	response.data.header = response.data.header || {};
	response.data.header.result = response.data.header.result || false;
	response.data.header.isFail = !response.data.header.result;

	return response;
};

const requestPost = async (...args) => {
	let url = '';
	let payload = {};

	switch (args.length) {
		case 2:
			payload = args[1];
		// eslint-disable-next-line no-fallthrough
		case 1:
			url = args[0];
			break;
		default:
			throw new Error('데이터 처리 중 문제가 발생하였습니다.');
	}
	const response = await restClient.post(url, payload);

	initResponseData(response);

	let result = response.data.header.result;
	let message = response.data.header.message;
	let data = response.data.body.data;

	return {result, data, message};
}

export {initResponseData, requestPost};