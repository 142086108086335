export default {
    lpad(str, len, padStr) {
        if (padStr.length > len) {
            return str;
        }
        str += "";
        padStr += "";
        while (str.length < len) {
            str = padStr + str;
        }
        str = str.length >= len ? str.substring(0, len) : str;
        return str;
    },
    rpad(str, len, padStr) {
        if (padStr.length > len) {
            return str + "";
        }
        str += "";
        padStr += "";
        while (str.length < len) {
            str += padStr;
        }
        str = str.length >= len ? str.substring(0, len) : str;
        return str;
    },
    comma(val) {
        return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
};
