//common
export default [
	{
		name: '404Page',
		path: '*',
		component: () => import('@/views/common/404Page.vue'),
		meta: { menuName: 'DB손해보험 헬스케어', typeName: 'fourth' },
	},
	{
		name: 'Error',
		path: '/common/error',
		component: () => import('@/views/common/ErrorPage.vue'),
	},
	{
		name: 'UnderMaintenance',
		path: '/common/under-maintenance',
		component: () => import('@/views/common/UnderMaintenance.vue'),
		meta: { menuName: 'DB손해보험 헬스케어', typeName: 'fourth' },
	},
	{
		name: 'External2Page',
		path: '/external2/health-mall',
		component: () => import('@/views/common/External2Page.vue'),
		meta: { menuName: '웰니스몰', typeName: 'home', footer: true },
	},
	{
		name: 'ExternalPage',
		path: '/external/:path',
		component: () => import('@/views/common/ExternalPage.vue'),
		meta: (route) => ({menuName: route.params.menuName, typeName: 'home'}),
	},
	{
		name: 'DetailTerms',
		path: '/term/detail',
		component: () => import('@/views/common/term/DetailTerms.vue'),
		meta: (route) => ({menuName: route.params.menuName, typeName: 'third'}),
	},
];
