export default [
	{
		name: 'CustomizedCare',
		path: '/contents/customized-care/:division',
		component: () => import('@/views/contents/CustomizedCare.vue'),
		meta: { menuName: '콘텐츠', typeName: 'main', footer: true },
	},
	{
		name: 'CareDetail',
		path: '/contents/customized-care-detail/:division/:id',
		component: () => import('@/views/contents/CustomizedCareDetail.vue'),
		meta: { menuName: '콘텐츠', typeName: 'main' },
	},
	{
		name: 'RecipeDetail',
		path: '/contents/customized-recipe-detail/:division/:id',
		component: () => import('@/views/contents/CustomizedRecipeDetail.vue'),
		meta: { menuName: '맞춤 레시피' },
	},
	{
		name: 'StartExercise',
		path: '/contents/start-exercise',
		component: () => import('@/views/contents/StartExercise.vue'),
		meta: { typeName: 'home' },
	},
	{
		name: 'ExercisePreview',
		path: '/contents/exercise-preview',
		component: () => import('@/views/contents/ExercisePreview.vue'),
		meta: { menuName: '운동 미리보기', typeName: 'home' },
	},
	{
		name: 'BackgroundMusic',
		path: '/contents/background-music',
		component: () => import('@/views/contents/BackgroundMusic'),
		meta: { menuName: '배경음악 선택', typeName: 'home' },
	},
]