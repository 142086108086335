export default [
	{
		name: 'Settings',
		path: '/setting',
		component: () => import('@/views/settings/Settings.vue'),
		meta: { menuName: '설정', typeName: 'home' },
	},
	{
		name: 'DeviceLinkage',
		path: '/setting/device-linkage',
		component: () => import('@/views/settings/DeviceLinkage.vue'),
		meta: { menuName: '기기연동', typeName: 'home' },
	},
	{
		name: 'PushAlarm',
		path: '/setting/push-alarm',
		component: () => import('@/views/settings/PushAlarm.vue'),
		meta: { menuName: '푸시 알림', typeName: 'home' },
	},
	{
		name: 'ConfirmMyInfo',
		path: '/setting/confirm-my-info/:name',
		component: () => import('@/views/settings/ConfirmMyInfo.vue'),
		meta: { menuName: '비밀번호 확인', typeName: 'home' },
	},
	{
		name: 'ManageMyInfo',
		path: '/setting/manage-my-info',
		component: () => import('@/views/settings/ManageMyInfo.vue'),
		meta: { menuName: '회원정보 관리', typeName: 'home' },
	},
	{
		name: 'ModifyMyInfo',
		path: '/setting/modify-my-info',
		component: () => import('@/views/settings/ModifyMyInfo.vue'),
		meta: { menuName: '회원정보 수정', typeName: 'home' },
	},
	{
		name: 'ModifyPassword',
		path: '/setting/modify-password',
		component: () => import('@/views/settings/ModifyPassword.vue'),
		meta: { menuName: '비밀번호 변경', typeName: 'home' },
	},
	{
		name: 'WithDrawGuide',
		path: '/setting/with-draw-guide',
		component: () => import('@/views/settings/WithDrawGuide.vue'),
		meta: { menuName: '고객센터', typeName: 'home' },
	},
	{
		name: 'Noti',
		path: '/common/noti',
		component: () => import('@/views/settings/NotiPage.vue'),
		meta: { menuName: '공지사항', typeName: 'home' }
	}
];
