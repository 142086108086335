// 공통 메뉴의 router 정보
import store from '@/store/index';

export default [
    /**
     * 로그인
     */
    {
        name     : 'Login',
        path     : '/login',
        component: () => import('@/views/membership/login/Login.vue'),
        meta     : {menuName: '로그인', typeName: 'fourth'},
    },
    {
        name     : 'Logout',
        path     : '/logout',
        component: () => import('@/views/membership/login/Login.vue'),
        meta     : {menuName: '로그인', typeName: 'fourth'},
        beforeEnter(to, from, next) {
            store.dispatch('logout');
            next();
        },
    },

    /**
     * 아이디 찾기
     */

    {
        name     : 'FindId',
        path     : '/login/find-id',
        component: () => import('@/views/membership/login/FindId.vue'),
        meta     : {
            menuName: '아이디/비밀번호 찾기',
            typeName: 'hideHomeButton',
            login   : true,
        },
    },

    /**
     * 비밀번호 찾기
     */
    {
        name     : 'FindPassword',
        path     : '/login/find-password',
        component: () => import('@/views/membership/login/FindPassword.vue'),
        meta     : {
            menuName: '아이디/비밀번호 찾기',
            typeName: 'hideHomeButton',
            login   : true,
        },
    },
    /**
     * 기존회원 추가 약관
     */
    {
        name: 'AddTerm',
        path: '/login/term',
        component: () => import('@/views/membership/login/AddTerm.vue'),
        meta: { menuName: '약관동의', typeName: 'hideHomeButton' },
    },
];
