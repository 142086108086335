import Vue from 'vue';

Vue.config.productionTip = false;

let sessionManager = {
	getMember() {
		return {
			token: sessionStorage.getItem('user-token') || '',
			name: sessionStorage.getItem('name') || '',
			user: sessionStorage.getItem('user') || '',
			uuid: sessionStorage.getItem('user-uuid') || '',
			refreshToken: sessionStorage.getItem('user-refreshToken') || '',
			individualList: JSON.parse(sessionStorage.getItem('individual-list')) || [],
			serviceList: JSON.parse(sessionStorage.getItem('service-list')) || [],
			lastDtm: sessionStorage.getItem('last-dtm') || '',
		};
	},
	setMember(params) {
		sessionStorage.setItem('user-token', params.token);
		sessionStorage.setItem('user', params.user);
		sessionStorage.setItem('user-refreshToken', params.refreshToken);
		sessionStorage.setItem('user-uuid', params.uuid);
		sessionStorage.setItem('name', params.name);
		sessionStorage.setItem('individual-list', JSON.stringify(params.individualList));
		sessionStorage.setItem('service-list', JSON.stringify(params.serviceList));
		sessionStorage.setItem('last-dtm', params.data.lastDtm);
	},
	setCustomizedTab(params) {
		sessionStorage.setItem('customizedTab', params.customizedTab);
	},
	removeMember() {
		sessionStorage.clear();
	},

	refreshMember(params) {
		sessionStorage.setItem('user-token', params.token);
		sessionStorage.setItem('user-refreshToken', params.refreshToken);
		sessionStorage.setItem('user-uuid', params.uuid);
	},
};

export { sessionManager };
