import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import membershipLogin from '@/routes/membership-login';
import memberJoin from '@/routes/membership-join';
import main from '@/routes/main';
import customerCenter from './customer-center';
import contents from './contents';
import healthAnalysis from './health-analysis';
import settings from './settings';
import common from './common';
import specialization from './specialization';
import mentalCare from './mental-care';
import healthInfo from '@/routes/health-info';
import diagnosis from '@/routes/diagnosis';

Vue.use(VueRouter);

const router = new VueRouter({
    mode  : 'history',
    routes: [
        ...membershipLogin,
        ...memberJoin,
        ...main,
        ...settings,
        ...customerCenter,
        ...common,
        ...contents,
        ...healthAnalysis,
        ...specialization,
        ...mentalCare,
        ...healthInfo,
        ...diagnosis,
    ],
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0};
    },
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => {
        if (err.name !== 'NavigationDuplicated') throw err;
    });
};

router.beforeEach((to, from, next) => {
    if (to.meta.auth && !store.getters.isLogin) {
        // TODO: alert 뜰수가 없는딩;;;
        // Vue.prototype.$alert('회원전용 서비스입니다.\n로그인 하시겠습니까?');
        next({name: 'Logout'});
        return;
    }

    const $body = $('body');

    $body.removeAttr('id').removeClass('bg_body_gray, bg_body_skyblue');

    if (to.name === 'Index') {
        $body.attr('id', 'main');
    } else if (to.meta.body) {
        $body.addClass('bg_body_skyblue');
    }

    next();
});

export default router;
