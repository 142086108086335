<template>
  <div id="confirmArea" class="modal" data-keyboard="false" data-backdrop="static">
    <div class="modal_popup">
      <div class="popup_inner" v-html="getAlertMsg">
      </div>
      <div class="popup_btn">
        <button type="button" id="confirmFalse" class="modal_close" data-dismiss="modal" aria-hidden="true"
          title="팝업 닫기">취소</button>
        <button type="button" id="confirmTrue" class="modal_close color_blue" data-dismiss="modal" aria-hidden="true"
          title="확인">확인</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ConfirmComponent",
  computed: {
    ...mapGetters(['getAlertMsg'])
  }
}
</script>

<style scoped></style>