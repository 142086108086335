/* 전역 변수 */
var ua = navigator.userAgent;
var windowWidth = $(window).width();
var windowHeight = $(window).height();


/* useagent check */
function userAgentChk(){
	if(ua.match(/iPhone|iPod|LG|Android|SAMSUNG|Samsung/i) != null){
		if (windowWidth > 1024){
			$("body").addClass("device").addClass("tablet");
			switch(window.orientation){ 
				case -90:
				$("body").addClass("tablet_landscape");
				$("body").addClass("pc").removeClass("tablet");
				break;
				case 90:
				$("body").addClass("tablet_landscape");
				$("body").addClass("pc").removeClass("tablet");
				break;
				case 0:
				$("body").addClass("tablet_portrait");
				$("body").removeClass("pc").removeClass("normal").addClass("tablet");
				break;
				case 180:
				$("body").addClass("tablet_portrait");
				$("body").removeClass("pc").removeClass("normal").addClass("tablet");
				break;
			 }
		}else{
			$("body").addClass("mobile").addClass("device");
			switch(window.orientation){  
				case -90:
				$("body").addClass("mobile_landscape")
				break;
				case 90:
				$("body").addClass("mobile_landscape");
				break;
				case 0:
				$("body").addClass("mobile_portrait");
				break;
				case 180:
				$("body").addClass("mobile_portrait");
				break;
			 }
		}
	}else if (ua.match(/iPad|GallaxyTab/i) != null){
		$("body").addClass("device").addClass("tablet");
		switch(window.orientation){ 
			case -90:
			$("body").addClass("tablet_landscape");
			$("body").addClass("pc").removeClass("tablet");
			break;
			case 90:
			$("body").addClass("tablet_landscape");
			$("body").addClass("pc").removeClass("tablet");
			break;
			case 0:
			$("body").addClass("tablet_portrait");
			$("body").removeClass("pc").removeClass("normal").addClass("tablet");
			break;
			case 180:
			$("body").addClass("tablet_portrait");
			$("body").removeClass("pc").removeClass("normal").addClass("tablet");
			break;
		 }
	}else{
		bodyClassChange();

		$(window).resize(function(){
			windowWidth = $(window).width();
			windowHeight = $(window).height();
			bodyClassChange();
		}).resize();

		if(ua.indexOf("MSIE 8.0") > -1 || ua.indexOf("Trident/4.0") > -1){ //IE8 이하일 경우
			$("body").addClass("pc").addClass("pc_ie8");
			if(ua.indexOf("Windows NT 6.2") > -1){
			}else if (ua.indexOf("Windows NT 6.1") > -1){			
				$("body").addClass("pc").addClass("pc_ie8").addClass("w7"); //window7, IE8
			}else if (ua.indexOf("Windows NT 5.1") > -1){
				$("body").addClass("pc").addClass("pc_ie8").addClass("xp"); //windowXP, IE8
			}
		}else if(ua.indexOf("MSIE 7.0") > -1 || ua.indexOf("MSIE 6.0") > -1){
			$("body").addClass("pc").addClass("pc_ie8");
		}else if(ua.indexOf("Trident") > -1){
			$("body").addClass("pc").addClass("ie");
		}else{ //IE9 PC 
			if (ua.indexOf("Chrome") > -1){
				$("body").addClass("pc").addClass("chrome");
			}else if(ua.indexOf("Mac") > -1){
				$("body").addClass("mac");
			}else{
				$("body").addClass("pc");
			}
		}
	}
}
userAgentChk();

function bodyClassChange(){
	if (windowWidth > 1025){
		$("body").removeClass("mobile_portrait").removeClass("mobile").removeClass("tablet").removeClass("smallbrowser").addClass("normal");
		$(".midarea").css("min-height", (windowHeight-$(".toparea").height()-$(".bottomarea").height())+"px");
		$(".topmenu").css("left","0");
		
	}else if (windowWidth <= 1024){
		$("body").removeClass("mobile_portrait").removeClass("normal").addClass("mobile").removeClass("smallbrowser").addClass("tablet");
        $(".midarea").css("min-height", (windowHeight-$(".toparea").height()-$(".bottomarea").height())+"px");
		$(".contents_box").removeClass("contents_web");
        
	}
}

$('.dropdown').dropselect();

/* 상단 메뉴 */
$( ".btn_allgnb" ).click(function(){
    $( ".allmenu" ).slideDown( 200 );
    $( ".allmenu" ).addClass( "active" );
    $( ".total_search_area" ).slideUp( 200 );
    $( ".top_bar" ).addClass( "active" );
    $( ".allmenu .btn_close" ).focus();
});
$( ".allmenu .btn_close" ).click(function(){
    $( ".allmenu" ).slideUp( 200 );
    $( ".allmenu" ).removeClass( "active" );
    $( ".top_bar" ).removeClass( "active" );
    $( ".btn_allgnb" ).focus();
});

/* 하단 네비게이션 active */
$(".navarea ul li").click(function(e) {
	e.stopPropagation();

	if (!$(this).hasClass("active")) {
		$(this).siblings().removeClass("active");
		$(this).addClass("active");
	}
});

/* 성별 선택 */
$(".input_area dl dd .gender button").click(function(e) {
	e.stopPropagation();

	if (!$(this).hasClass("active")) {
		$(this).siblings().removeClass("active");
		$(this).addClass("active");
	}
});

/* 자주 묻는 질문 */
$('.faq ul li').click(function(){
	$(this).siblings().children('.txt').slideUp();
	if ($(this).children('.txt').is(':hidden')){
		$(this).children('.txt').slideDown(300);
	} else{
		$(this).children('.txt').slideUp(300);
	}
});