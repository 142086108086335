import Vue from 'vue';
import Vuex from 'vuex';
import auth from "@/store/auth";
import bodyAnalysis from "./body-analysis";
import contents from "@/store/contents";

Vue.use(Vuex);

export default new Vuex.Store({
	namespaced: true,
	modules: {
		auth,
		bodyAnalysis,
		contents
	},
	state: {
		loadingStatus: false,
		userId: sessionStorage.getItem('user-id') || '',
		name: sessionStorage.getItem('user-name') || '',
		user: {},
		resultList: {},
		alertMsg: '',
	},
	getters: {
		isLogin(state) {
			return state.auth.name !== null && state.auth.name !== '';
		},
		getMemberInfo(state) {
			return state.user;
		},
		getAlertMsg(state) {
			return state.alertMsg;
		},
		getCustomerCenter(state) {
			return state.customerCenter;
		},
	},
	mutations: {
		setUserInfo(state, parameter) {
			state.userId = parameter.userId;
			state.name = parameter.name;
		},
		setUserId(state, parameter) {
			state.userId = parameter.userId;
		},
		clearUserId(state) {
			state.userId = null;
		},
		clearUserInfo(state) {
			state.name = null;
			state.userId = null;
		},
		setMemberInfo(state, memberInfo) {
			state.user = memberInfo;
		},
		setHealthCheckup(state, result) {
			state.certification = result;
		},
		setHealthCheckUpInfo(state, checkupInfo) {
			state.checkupInfo = checkupInfo;
		},
		setHealthCheckUpList(state, checkUpList) {
			state.resultList = checkUpList;
		},
		setMenuChk(state, menuId) {
			state.menuChk = menuId;
		},
		startSpinner(state) {
			state.loadingStatus = true;
		},
		endSpinner(state) {
			state.loadingStatus = false;
		},
		setAlertMsg(state, alertMsg) {
			state.alertMsg = alertMsg;
		},
	},
	actions: {
		setMemberInfo({ commit }, memberInfo) {
			commit('setMemberInfo', memberInfo);
		},
		getCertification({ commit }, inputVal) {
			commit('getCertification', inputVal);
		},
		setHealthCheckup({ commit }, result) {
			commit('setHealthCheckup', result);
		},
		setHealthCheckUpInfo({ commit }, { checkupInfo, checkUpList }) {
			commit('setHealthCheckUpList', checkUpList);
			commit('setHealthCheckUpInfo', checkupInfo);
		},
		fetchAlertMsg({ commit }, alertMsg) {
			$('#alertArea').modal('show');
			commit('setAlertMsg', alertMsg);
		},
		fetchConfirmMsg({ commit }, alertMsg) {
			$('#confirmArea').modal('show');
			commit('setAlertMsg', alertMsg);
		},
		fetchCustomerCenter({ commit }, customerCenter) {
			commit('setCustomerCenter', customerCenter);
		},
		fetchMenuName({ commit }, menuName) {
			commit('setMenuName', menuName);
		},
	},
});
