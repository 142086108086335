export default [
	//심리상담 서비스
	//재활 기기 지원
	{
		name: 'MentalCare',
		path: '/specialization/mental-care',
		component: () => import('@/views/specialization/mentalCare/SurveyList.vue'),
		meta: { menuName: '현물 급부' },
	},
];
