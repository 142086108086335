<template>
    <div class="allmenu" ref="allmenu">
        <div class="allmenu_in">
            <div class="allmenu_header">
                <span class="user">{{ loginUserName }} 님</span>
                <div class="btnarea">
                    <button @click="goRouter('Settings')" type="button" class="btn_set" title="설정">
                        <span class="icon"></span>
                    </button>
                    <button @click="exit" type="button" class="btn_close" title="닫기"><span class="icon"></span></button>
                </div>
            </div>

            <div class="allmenu_menu">
                <template v-if="!isMedicalAppointment">
                    <h2 class="tit0">진료 예약</h2>
                    <ul class="box box0">
                        <li>
                            <router-link :to="{name:'MedicalAppointment'}">진료 예약</router-link>
                        </li>
                        <li>
                            <router-link :to="{name: 'MedicalInquiry'}">내역 조회</router-link>
                        </li>
                    </ul>
                </template>
                <h2 class="tit1">검진 예약</h2>
                <ul class="box box1">
                    <li>
                        <router-link :to="{ name: 'ExternalPage', params: { path: 'examine', menuName: '검진 예약' } }">검진
                            예약
                        </router-link>
                    </li>
                </ul>
                <template v-if="isExistIndividual">
                    <h2 class="tit2">특화 서비스</h2>
                    <ul class="box box2">
                        <li v-for="item in individualList" :key="item">
                            <router-link to="" @click.native="onClickSelectProfessionalTab(item)">
                                {{ serviceName(item) }}
                            </router-link>
                        </li>
                        <li v-if="isIndividualCare">
                            <router-link
                                    :to="{ name: 'Specialization', params: { serviceDiv: 'care', selectType: 'history' } }">
                                내역 조회
                            </router-link>
                        </li>
                    </ul>
                </template>
                <h2 class="tit3">건강 및 콘텐츠</h2>
                <ul class="box box3">
                    <li>
                        <router-link to="" @click.native="goRouter('AnalysisRecord')">체형 분석</router-link>
                    </li>
                    <li>
                        <router-link to="" @click.native="onClickSelectMedicalTab">건강정보</router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'CustomizedCare', params: {division: 'ex'}}">콘텐츠</router-link>
                    </li>
                </ul>
                <ul class="box box4">
                    <li class="m1">
                        <router-link to="" @click.native="goRouter('CustomerServiceCenter')">고객센터</router-link>
                    </li>
                    <li class="m2">
                        <router-link :to="{ name: 'ExternalPage', params: { path: 'health-mall', menuName: '웰니스몰' } }">
                            웰니스몰
                        </router-link>
                    </li>
                </ul>
            </div>

            <div class="allmenu_membership">
                <button @click="onClickLogout" type="button" title="로그아웃">로그아웃</button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapMutations} from 'vuex';
import {sessionManager} from "@/utils/session-manager";

export default {
    name: 'MenuBar',
    computed: {
        loginUserName() {
            const auth = this.$store.state.auth || {};
            return auth.name || '';
        },
        individualList() {
            return sessionManager.getMember().individualList || [];
        },
        isExistIndividual() {
            return this.individualList.length > 0;
        },
        isIndividualCare() {
            return this.individualList.includes('care');
        },
        isMedicalAppointment() {
            return this.serviceList.includes('1135') && this.serviceList.length === 1;
        },
        serviceList() {
            return sessionManager.getMember().serviceList || [];
        },
    },
    methods : {
        serviceName(item) {
            if (item === 'care') {
                return '치료 지원 서비스';
            } else if (item === 'cash') {
                return '현물 급부 서비스';
            } else if (item === 'mental') {
                return '멘탈 케어 서비스';
            }
            return '';
        },
        goRouter(name) {
            this.$router.push({name});
            this.exit();
        },
        exit() {
            $(".allmenu").slideUp(200);
            $(".allmenu").removeClass("active");
            $(".top_bar").removeClass("active");
            $(".btn_allgnb").focus();
        },
        onClickLogout() {
            localStorage.removeItem('auto-login-token');
            this.$router.push({name: 'Logout'});
        },
        onClickSelectProfessionalTab(serviceDiv) {
            this.$router.push({name: 'Specialization', params: {serviceDiv}});
            this.exit();
        },
        onClickSelectMedicalTab() {
            if (this.serviceList.includes('1137')) {
                this.$router.push({name: 'HealthInfoList', params: {category: 'list'}});
            } else if (['1140', '1143'].filter(e => this.serviceList.includes(e)).length > 0) {
                this.$router.push({name: 'ExternalPage', params: {path: 'senior-care', menuName: '건강 정보'}});
            } else {
                this.$router.push({name: 'ExternalPage', params: {path: 'official-sns', menuName: '건강 정보'}});
            }
        }
    }
}
</script>