<template>
    <div class="subtab2">
        <ul>
            <li :class="{ active: $route.name === 'AnalysisRecord' }"><router-link :to="{name: 'AnalysisRecord'}">분석 기록</router-link>
            </li>
            <li :class="{ active: $route.name === 'BodyTypeRegistration' }"><router-link :to="{name: 'BodyTypeRegistration'}">체형 등록</router-link>
            </li>
            <li :class="{ active: $route.name === 'BodyAnalysis' }"><router-link :to="{name: 'BodyAnalysis'}">결과 보기</router-link>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
};
</script>