export default [
	//고객문의
	{
		name: 'CustomerServiceCenter',
		path: '/center/customer-service-center',
		component: () => import('@/views/center/CustomerServiceCenter'),
		meta: { menuName: '고객센터', typeName: 'home' },
	},
	//자주 묻는 질문
	{
		name: 'Faq',
		path: '/center/faq',
		component: () => import('@/views/center/Faq.vue'),
		meta: { menuName: '자주 묻는 질문', typeName: 'home' },
	},
];
