import programService from '@/service/program-service';

const state = {
    detailInfo: {},
    customizedCareType: {}
};

const getters = {
    getDetailInfo(state) {
        return state.detailInfo;
    },
    getCustomizedCareType(state){
        return state.customizedCareType;
    }
};

const mutations = {
    setDetailInfo(state, payload) {
        state.detailInfo = {};
        state.detailInfo = payload;
    },
    setCustomizedCareType(state, payload){
        state.customizedCareType = payload;
    }
};

const actions = {
    async customCareDetail(context, payload) {
        const response = await programService.programDetail(payload);
        if (response.result) {
            context.commit('setDetailInfo', response.data);
        }
        return response;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};