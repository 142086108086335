import Vue from "vue";
import StringUtils from "@/utils/string-utils";

Vue.filter('mask', function (value) {
    if (!value) {
        return '';
    }
    return value.slice(0, -3).padEnd(value.length, '*');
});
Vue.filter('nameMask', function (value) {
    if (!value) {
        return '';
    }
    return value.slice(0, -1).padEnd(value.length, '*');
});

Vue.filter('convertDateFormat', (value) => {
    if (!value) {
        return '';
    }

    let js_date = new Date(value);
    let year = js_date.getFullYear();
    let month = js_date.getMonth() + 1;
    let day = js_date.getDate();

    return year + '.' + StringUtils.lpad(month, 2, '0') + '.' + StringUtils.lpad(day, 2, '0');
});

Vue.filter('lpad', StringUtils.lpad);
Vue.filter('rpad', StringUtils.rpad);
Vue.filter('comma', StringUtils.comma);