import { sessionManager } from '@/utils/session-manager';
import loginService from '@/service/login-service';

const state = {
	token: sessionManager.getMember().token,
	user: sessionManager.getMember().user,
	uuid: sessionManager.getMember().uuid,
	refreshToken: sessionManager.getMember().refreshToken,
	name: sessionManager.getMember().name,
	individualList: sessionManager.getMember().individualList,
	serviceList: sessionManager.getMember().serviceList,
	lastDtm: sessionManager.getMember().lastDtm
};

const getters = {
	isAuthenticated: (state) => {
		return !!state.token;
	},
};

const mutations = {
	setToken: (state, token) => {
		state.token = token;
	},
	removeToken: (state) => {
		state.token = '';
	},
	setUser: (state, user) => {
		state.user = user;
	},
	removeUser: (state) => {
		state.user = null;
	},
	setUuid: (state, uuid) => {
		state.uuid = uuid;
	},
	removeUuid: (state) => {
		state.uuid = null;
	},
	setUserName: (state, name) => {
		state.name = name;
	},
	removeUserName: (state) => {
		state.name = null;
	},
	removeIndividualList: (state) => {
		state.individualList = null;
	},
	removeServiceList: (state) => {
		state.serviceList = null;
	},
	setRefreshToken: (state, refreshToken) => {
		state.refreshToken = refreshToken;
	},
	removeRefreshToken: (state, refreshToken) => {
		state.refreshToken = null;
	},

	setLoginInfo(state, payload) {
		state.uuid = payload.uuid;
		state.name = payload.name;
		state.user = payload.user;
		state.token = payload.token;
		state.refreshToken = payload.refreshToken;
		state.individualList = payload.individualList;
		state.serviceList = payload.serviceList;
		state.lastDtm = payload.lastDtm;
		sessionManager.setMember(payload);
	},
};

const actions = {
	async login(context, payload) {
		const response = await loginService.loginProc(payload);

		if (response.result) {
			context.commit('setLoginInfo', response);
		}

		return response;
	},
	async autoLogin(context, payload) {
		const response = await loginService.autoLoginProc(payload);

		if (response.result) {
			context.commit('setLoginInfo', response);
		}

		return response;
	},
	logout: ({ commit }) => {
		commit('removeToken');
		commit('removeUser');
		commit('removeRefreshToken');
		commit('removeUuid');
		commit('removeUserName');
		commit('removeIndividualList');
		commit('removeServiceList');
		sessionStorage.clear();
	},
	//세션 만료
	setTimeEnd: ({ commit }) => {
		commit('removeToken');
		sessionStorage.removeItem('user-token');
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
