import { requestPost } from '@/service/common/common';

export default {
	async programList(payload) {
		return await requestPost('/program/list.api', payload);
	},
	async programDetail(payload) {
		return await requestPost('/program/detail.api', payload);
	},
	async programPreview(payload) {
		return await requestPost('/program/ex-preview.api', payload);
	},
    async addBodyType(payload) {
        return await requestPost('/body-type/add.api', payload);
	},
	async bodyTypeDetail(payload) {
		return await requestPost('/body-type/detail.api', payload);
	},
	async bodyTypeAnalysis() {
		return await requestPost('/body-type/list.api');
	},
};
