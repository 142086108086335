import programService from '@/service/program-service';

const state = {
    arrId: [],
    detailObj: {},
    id: ''
};

const getters = {
    getArrId(state) {
        return state.arrId;
    },
    getBodyDetailObj(state) {
        return state.detailObj;
    }
};

const mutations = {
    setArrId(state, payload) {
        state.arrId = [];
        state.arrId = payload;
    },
    setBodyDetailObj(state, payload) {
        state.detailObj = {};
        Object.keys(payload).forEach(key => state.detailObj[key] = payload[key]);
    },
    setIdInfo(state, payload) {
        state.id = payload.id;
    }
};

const actions = {
    async bodyRecodeInfo(context) {
        const response = await programService.bodyTypeAnalysis();
        if (response.result) {
            context.commit('setArrId', response.data);
        }
        return response;
    },
    async bodyDetailInfo(context, payload) {
        const response = await programService.bodyTypeDetail(payload);
        if (response.result) {
            context.commit('setBodyDetailObj', response.data);
        }
        return response;
    },
    async bodyInfoReg(context, payload) {
        const response = await programService.addBodyType(payload);
        if (response.result) {
            context.commit('setIdInfo', response.data);
            await context.dispatch('bodyDetailInfo', {id: response.data});
        }
        return response;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};