export default [
	{
		name: 'AnalysisRecord',
		path: '/health-analysis/analysis-record',
		component: () => import('@/views/healthAnalysis/AnalysisRecord.vue'),
		meta: { auth: true, menuName: '체형분석', typeName: 'main', body: true, footer: true, },
	},
	{
		name: 'BodyTypeRegistration',
		path: '/health-analysis/body-type-registration',
		component: () => import('@/views/healthAnalysis/BodyTypeRegistration.vue'),
		meta: { auth: true, menuName: '체형분석', typeName: 'main', footer: true, },
	},
	{
		name: 'BodyAnalysis',
		path: '/health-analysis/body-analysis',
		component: () => import('@/views/healthAnalysis/BodyAnalysis.vue'),
		meta: { auth: true, menuName: '체형분석', typeName: 'main', body: true, footer: true, },
	}
];